<template>
    <div class="boxMaking">
        <div class="makingBox">
            <div class="titles"><span>编辑订单</span></div>

            <div class="makingContent">
                <!-- 提单号 -->
                <div class="blNo">
                    <span>提单号：{{ mainBillNo.billNo }}</span>
                </div>
                <!-- 箱量 -->
                <div class="boxNum"><span>箱量：{{ tableDetailList.length }}</span></div>
                <!-- 批量修改 -->
                <div class="batchModification"><span>批量修改</span></div>
                <!-- 表格 -->
                <div class="tabs">
                    <div>
                        <el-table border ref="multipleTable" :data="tableDetailList" tooltip-effect="dark"
                            style="width: 100%" :header-row-style="{ height: '50px' }" :row-style="{ height: '56px' }"
                            :header-cell-style="{
                                'background': 'rgba(112, 112, 112, .1)'
                            }" @selection-change="handleSelectionChange">
                            <el-table-column type="selection" width="63">
                            </el-table-column>
                            <el-table-column type="index" label="序号" width="100">
                                <!-- <template slot-scope="scope">{{ scope.row.date }}</template> -->
                            </el-table-column>
                            <!-- <el-table-column prop="state" label="状态" width="92">
                            </el-table-column> -->
                            <el-table-column prop="id" label="箱号" show-overflow-tooltip>
                                <!-- <template slot-scope="scope">
                                    <div>
                                        <el-date-picker v-model="scope.row.address" value-format="yyyy-MM-dd"
                                            type="date" placeholder="选择日期">
                                        </el-date-picker>
                                        <el-time-select v-model="boxMakingTimeVal" :picker-options="{
                                            start: '08:30',
                                            step: '00:15',
                                            end: '18:30'
                                        }" placeholder="选择时间">
                                        </el-time-select>
                                    </div>
                                </template> -->
                            </el-table-column>
                            <el-table-column prop="boxType" label="箱型">
                            </el-table-column>
                            <el-table-column prop="pkg" label="件数" width="140">
                                <template slot-scope="scope">
                                    <div>
                                        <el-input v-model="scope.row.pkg" placeholder="件数"></el-input>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="kgs" label="货重(kg)" width="140">
                                <template slot-scope="scope">
                                    <div>
                                        <el-input v-model="scope.row.kgs" placeholder="货重(kg)"></el-input>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="cbm" label="体积(m3)" width="140">
                                <template slot-scope="scope">
                                    <div>
                                        <el-input v-model="scope.row.cbm" placeholder="体积(m3)"></el-input>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="productName" label="品名">
                                <template slot-scope="scope">
                                    <div>
                                        <el-input v-model="scope.row.productName" placeholder="品名"></el-input>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <!-- 按钮 -->
                <div class="bottomBtn">
                    <div class="cancelButton" @click="handleCancel"><span>取消</span></div>
                    <div class="okButton" @click="handleOk"><span>确定</span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { baseUrl } from '../../tools/baseUrl'
export default {
    name: "BoxMakingTime_Component",
    props: ['changecontainer', 'orderNo', 'mainBillNo'],
    data() {
        return {
            boxMakingDateVal: "",
            boxMakingTimeVal: "",
            tableData: [{
                state: '处理中',
                caseNo: '1312312321312',
                boxType: '20GP',
                numberS: '',
                cargoWeight: "",
                volume: "",
                productName: ""
            }],
            multipleSelection: [],
            tableDetailList: [],

            mainBillNoList: this.mainBillNo
        }
    },
    created() {
        this.getCargoList();

    },
    mounted() {
        this.getTrailerDetail()
    },
    methods: {
        getCargoList() {
            let id = this.mainBillNo.id;
            let token = sessionStorage.getItem("token");
            axios.get(`${baseUrl}/trailer/cargoList`, {
                //参数列表
                params: { id },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    console.log(res.data.result.length);
                    this.tableDetailList = res.data.result;
                })
                .catch(err => {
                    console.error(err);
                })
        },
        getTrailerDetail() {
            let orderNo = this.mainBillNo.orderNo;
            let token = sessionStorage.getItem("token");
            axios.get(`${baseUrl}/trailer/detail`, {
                //参数列表
                params: { orderNo },
                //请求头配置  
                headers: { 'authorization': token }
            }).then(res => {
                this.tableDetailList = res.data.result.pullcartExtList
                // this.detailList = res.data.result;
                // this.primaryID = res.data.result.id
                console.log(res);
            })
                .catch(err => {
                    console.error(err);
                })
        },

        handleSelectionChange(val) {
            this.multipleSelection = val;
            console.log(val);
        },
        handleCancel() { //取消
            this.changecontainer()
        },
        handleOk() { //确定
            console.log(this.mainBillNoList.id);
            let mainId = this.mainBillNoList.id
            let muList = this.multipleSelection;
            var token = sessionStorage.getItem("token");
            let data = []
            muList.forEach((v, i) => {
                data.push({
                    id: v.id,
                    pkg: v.pkg,
                    kgs: v.kgs,
                    cbm: v.cbm,
                    productName: v.productName
                })
                console.log(data);
            });
            axios.post(`${baseUrl}/trailer/editContainer`, {
                id: mainId,   //主订单id
                data
            }, {
                headers: {
                    'authorization': token
                }
            }).then(res => {
                console.log(res);
                if (res.data.success == true) {
                    let message = res.data.result;
                    this.$message({
                        showClose: true,
                        message: message,
                        type: 'success'
                    });
                    this.changecontainer()
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.boxMaking {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.3);
    z-index: 100;

    .makingBox {
        width: 1100px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
        border-radius: 8px 8px 8px 8px;
        margin: 100px auto 0;
        padding-bottom: 16px;

        .titles {
            height: 56px;
            font-size: 24px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            border-bottom: 1px solid rgba(153, 153, 153, .5);
            line-height: 56px;
            padding-left: 25px;
        }

        .makingContent {
            padding: 0 24px;
            margin-top: 25px;

            .blNo {
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
            }

            .boxNum {
                margin-top: 24px;
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
            }

            .batchModification {
                width: 96px;
                height: 28px;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid rgba(153, 153, 153, .5);
                margin-top: 16px;
                text-align: center;
                line-height: 28px;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #2B2B2B;
            }

            .tabs {
                margin-top: 16px;

                ::v-deep .el-input {
                    width: 100px;
                    margin-left: 10px !important;
                    // margin: auto;
                }

                .el-input:nth-child(1) {
                    margin-left: 0;
                }
            }

            .bottomBtn {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 66px;
                border-top: 1px solid rgba(153, 153, 153, 1);
                margin-top: 24px;

                div {
                    width: 60px;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                    border-radius: 4px 4px 4px 4px;
                    cursor: pointer;
                }

                .cancelButton {
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    margin-right: 24px;
                }

                .okButton {
                    background: #1890FF;
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
    }
}
</style>