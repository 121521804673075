<template>
    <div class="boxMaking">
        <div class="makingBox">
            <div class="titles"><span>编辑订单</span></div>

            <div class="makingContent">
                <!-- 提单号 -->
                <div class="blNo">
                    <span>提单号：{{ detailList.billNo }}</span>
                </div>
                <!-- 箱量 -->
                <div class="boxNum"><span v-if="detailList.pullcartExtList">箱量：{{ detailList.pullcartExtList.length
                }}</span></div>
                <!-- 批量修改 -->
                <div class="batchModification"><span>批量修改</span></div>
                <!-- 表格 -->
                <div class="tabs">
                    <div>
                        <el-table ref="multipleTable" :data="tableList" border tooltip-effect="dark" style="width: 100%"
                            :header-row-style="{ height: '50px' }" :row-style="{ height: '56px' }" :header-cell-style="{
                                'background': 'rgba(112, 112, 112, .1)'
                            }" @selection-change="handleSelectionChange">
                            <el-table-column type="selection" width="55">
                            </el-table-column>
                            <el-table-column type="index" width="120">

                            </el-table-column>
                            <el-table-column prop="id" label="箱号" width="200">
                            </el-table-column>
                            <el-table-column prop="address" label="做箱时间"  >
                                <template slot-scope="scope">
                                    <div>
                                        <!-- <el-date-picker v-model="scope.row.boxMakingTime" value-format="yyyy-MM-dd"
                                            type="date" placeholder="选择日期">
                                        </el-date-picker> -->
                                        <el-date-picker v-model="scope.row.boxMakingTime" type="datetime"
                                            placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss">
                                        </el-date-picker>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <!-- 按钮 -->
                <div class="bottomBtn">
                    <div class="cancelButton" @click="handleCancel"><span>取消</span></div>
                    <div class="okButton" @click="handleOk"><span>确定</span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { baseUrl } from '../../tools/baseUrl'
export default {
    name: "BoxMakingTime_Component",
    props: ['changeboxMaking', 'orderNo'],
    data() {
        return {
            boxMakingDateVal: "",
            boxMakingTimeVal: "",
            tableData: [{
                date: '2016-05-03',
                name: '王小虎',
                address: ''
            }],
            multipleSelection: [],
            tableList: [],
            detailList: [],
            primaryID: '',
        }
    },
    created() {
        this.getTrailerDetail()
    },
    methods: {
        getTrailerDetail() {
            let orderNo = this.orderNo;
            let token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/trailer/detail`, {
                //参数列表
                params: { orderNo },
                //请求头配置  
                headers: { 'authorization': token }
            }).then(res => {
                this.tableList = res.data.result.pullcartExtList
                this.detailList = res.data.result;
                this.primaryID = res.data.result.id
                console.log(this.primaryID);
            })
                .catch(err => {
                    console.error(err);
                })
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            console.log(val);
        },
        handleCancel() { //取消
            this.changeboxMaking()
        },
        handleOk() { //确定
            console.log(this.multipleSelection);
            if (this.multipleSelection.length == 0) {
                this.$message({
                    showClose: true,
                    message: '请选择要修改的时间',
                    type: 'error'
                });
                return false
            }
            let muList = this.multipleSelection;
            var token = sessionStorage.getItem("token");
            let data = []
            muList.forEach((v, i) => {
                data.push({
                    id: v.id,
                    box_making_time: v.boxMakingTime
                })
                console.log(data);
            })
            axios.post(`${baseUrl}/trailer/editMakingTime`, {
                id: this.primaryID,   //主订单id
                data
            }, {
                headers: {
                    'authorization': token
                }
            })
                .then(res => {
                    console.log(res);
                    if (res.data.success == true) {
                        let message = res.data.result;
                        this.$message({
                            showClose: true,
                            message: message,
                            type: 'success'
                        });
                        this.changeboxMaking()
                    }
                })
                .catch(err => {
                    console.error(err);
                })
        }
    },
}
</script>

<style lang="scss" scoped>
.boxMaking {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.30);
    z-index: 100;

    .makingBox {
        width: 1100px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
        border-radius: 8px 8px 8px 8px;
        margin: 100px auto 0;
        padding-bottom: 16px;

        .titles {
            height: 56px;
            font-size: 24px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            border-bottom: 1px solid rgba(153, 153, 153, .5);
            line-height: 56px;
            padding-left: 25px;
        }

        .makingContent {
            padding: 0 24px;
            margin-top: 25px;

            .blNo {
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
            }

            .boxNum {
                margin-top: 24px;
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
            }

            .batchModification {
                width: 96px;
                height: 28px;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid rgba(153, 153, 153, .5);
                margin-top: 16px;
                text-align: center;
                line-height: 28px;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #2B2B2B;
            }

            .tabs {
                margin-top: 16px;

                .el-input {
                    width: 240px;
                    margin-left: 16px;
                }

                .el-input:nth-child(1) {
                    margin-left: 0;
                }
            }

            .bottomBtn {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 66px;
                border-top: 1px solid rgba(153, 153, 153, 1);
                margin-top: 24px;

                div {
                    width: 60px;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                    border-radius: 4px 4px 4px 4px;
                    cursor: pointer;
                }

                .cancelButton {
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    margin-right: 24px;
                }

                .okButton {
                    background: #1890FF;
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
    }
}
</style>