<template>
    <div class="boxMaking">
        <div class="makingBox">
            <div class="titles"><span>编辑订单</span></div>

            <div class="makingContent">
                <!-- 提单号 -->
                <div class="blNo">
                    <span>提单号：{{ mainBillNo.billNo }}</span>
                </div>
                <!-- 箱量 -->
                <div class="boxNum"><span>箱量：{{ tableDetailList.length }}</span></div>
                <!-- 批量修改 -->
                <div class="batchModification">
                    <span>新中转港：</span>
                    <div class="ipt">
                        <el-input v-model="newTransitPort" placeholder="新中转港"></el-input>
                    </div>
                </div>
                <!-- 表格 -->

                <!-- 按钮 -->
                <div class="bottomBtn">
                    <div class="cancelButton" @click="handleCancel"><span>取消</span></div>
                    <div class="okButton" @click="handleOk"><span>确定</span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { baseUrl } from '../../tools/baseUrl'
export default {
    name: "BoxMakingTime_Component",
    props: ['changetransitPort', 'mainBillNo'],
    data() {
        return {
            boxMakingDateVal: "",
            boxMakingTimeVal: "",
            tableData: [{
                date: '2016-05-03',
                name: '王小虎',
                address: ''
            }],
            multipleSelection: [],
            newTransitPort: '', //中转港
            tableDetailList: []
        }
    },
    created() {
        this.getCargoList();


        this.getTrailerDetail()
    },
    methods: {
        handleSelectionChange(val) {
            this.multipleSelection = val;
            console.log(val);
        },
        getCargoList() {
            console.log(this.mainBillNo);
            let id = this.mainBillNo.id;
            let token = sessionStorage.getItem("token");
            axios.get(`${baseUrl}/trailer/cargoList`, {
                //参数列表
                params: { id },
                //请求头配置  
                headers: { 'authorization': token }
            }).then(res => {
                console.log(res.data.result);
                this.tableDetailList = res.data.result;
            })
        },
        getTrailerDetail() {
            let orderNo = this.mainBillNo.orderNo;
            let token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/trailer/detail`, {
                //参数列表
                params: { orderNo },
                //请求头配置  
                headers: { 'authorization': token }
            }).then(res => {
                // this.tableList = res.data.result.pullcartExtList
                // this.detailList = res.data.result;
                // this.primaryID = res.data.result.id
                // console.log();
                this.newTransitPort = res.data.result.entreport;
            })
        },
        handleCancel() { //取消
            this.changetransitPort()
        },
        handleOk() { //确定
            console.log(this.newTransitPort);
            var token = sessionStorage.getItem("token");
            axios.post(`${baseUrl}/trailer/editTransfer`, {
                id: this.mainBillNo.id, //主订单id
                entreport: this.newTransitPort,
            }, {
                headers: {
                    'authorization': token
                }
            }).then(res => {
                console.log(res);
                if (res.data.success == true) {
                    let message = res.data.result;
                    this.$message({
                        showClose: true,
                        message: message,
                        type: 'success'
                    });
                    this.changetransitPort()
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.boxMaking {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.3);
    z-index: 100;

    .makingBox {
        width: 1100px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
        border-radius: 8px 8px 8px 8px;
        margin: 100px auto 0;
        padding-bottom: 16px;

        .titles {
            height: 56px;
            font-size: 24px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            border-bottom: 1px solid rgba(153, 153, 153, .5);
            line-height: 56px;
            padding-left: 25px;
        }

        .makingContent {
            padding: 0 24px;
            margin-top: 25px;

            .blNo {
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
            }

            .boxNum {
                margin-top: 24px;
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
            }

            .batchModification {
                display: flex;
                height: 32px;
                border-radius: 4px 4px 4px 4px;
                margin-top: 80px;
                text-align: center;
                line-height: 32px;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #2B2B2B;
            }

            .tabs {
                margin-top: 16px;

                .el-input {
                    width: 240px;
                    margin-left: 16px;
                }

                .el-input:nth-child(1) {
                    margin-left: 0;
                }
            }

            .bottomBtn {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 66px;
                border-top: 1px solid rgba(153, 153, 153, 1);
                margin-top: 24px;

                div {
                    width: 60px;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                    border-radius: 4px 4px 4px 4px;
                    cursor: pointer;
                }

                .cancelButton {
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    margin-right: 24px;
                }

                .okButton {
                    background: #1890FF;
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
    }
}
</style>