<template>
    <div class="trailerBox">
        <div class="trailerServer">
            <div class="flexibleLayout">
                <!-- 提单号 -->
                <div class="blNo">
                    <el-input v-model="blNoValue" placeholder="提单号"></el-input>
                </div>
                <!-- 订单状态 -->
                <div class="orderStatus selectItem">
                    <el-select v-model="orderStatusValue" placeholder="订单状态">
                        <el-option v-for="item in orderStatusList" :key="item.en_name" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <!-- 港口 -->
                <div class="port selectItem">
                    <el-select v-model="portValue" placeholder="港口" v-el-select-loadmore="loadmore" @focus="changeFous"
                        filterable :filter-method="remoteMethod">
                        <el-option v-for="item in portList" :key="item.name" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                </div>
                <!-- 下单范围日期 -->
                <div class="orderRange">
                    <el-date-picker v-model="orderRangeValue" type="daterange" range-separator="-"
                        start-placeholder="开始日期" end-placeholder="结束日期" prefix-icon="none" value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <!-- <el-date-picker v-model="orderRangeValue" type="datetime" placeholder="选择日期时间"
                        value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker> -->
                    <!-- <el-date-picker v-model="orderRangeValue" type="datetimerange" start-placeholder="开始日期"
                        end-placeholder="结束日期" :default-time="['12:00:00']" value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker> -->
                </div>
                <!-- 门店地址 -->
                <div class="gatePointAddress">
                    <el-input v-model="addressValue" placeholder="门店地址"></el-input>
                </div>
            </div>
            <div class="flexibleLayout" style="margin-top: 16px;">
                <!-- 箱号 -->
                <div class="caseNo selectItem2">
                    <el-input v-model="caseNoValue" placeholder="箱号"></el-input>
                </div>
                <!-- 业务编号 -->
                <div class="businessNo selectItem2" style="margin-left: 32px;">
                    <el-input v-model="businessNoValue" placeholder="业务编号"></el-input>
                </div>
                <!-- 按钮 -->
                <div class="searchButton" @click="handleSearch">
                    <i class="el-icon-search"></i>
                    <span style="margin-left: 8px;">搜索</span>
                </div>
                <div class="resetButton" @click="handerReset">
                    <span>重置</span>
                </div>
            </div>

        </div>
        <!-- 表格 -->
        <div class="tabs">
            <div>
                <el-table :data="TrailerOrderList" border stripe style="width: 100%"
                    :header-row-style="{ height: '50px' }" :row-style="{ height: '56px' }">
                    <el-table-column prop="date" label="操作" width="120" border fixed class-name="nanas">
                        <template slot-scope="scope">
                            <el-button @click="handleAnotherOrder(scope.row)" type="text" size="small">再来一单</el-button>
                            <el-button type="text" size="small" @click="handleSee(scope.row)">查看</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="编辑" width="210" fixed class-name="nanas" border>
                        <template slot-scope="scope">
                            <el-button @click="handleBoxMakingTime(scope.row)" type="text" size="small">做箱时间</el-button>
                            <el-button @click="handleContainer(scope.row)" type="text" size="small">箱货信息</el-button>
                            <el-button @click="handletransitPort(scope.row)" type="text" size="small">中转港</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="billNo" label="提单号" width="150" fixed class-name="nanas">
                        <template slot-scope="scope">
                            <el-button @click="handleBlNo(scope.row)" type="text" size="small">{{ scope.row.billNo }}
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orderNo" label="订单号" width="200"></el-table-column>
                    <el-table-column label="订单状态" width="150" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div>
                                <p v-if="scope.row.status == -1">已取消</p>
                                <p v-if="scope.row.status == 1">已下单</p>
                                <p v-if="scope.row.status == 2">已接单</p>
                                <p v-if="scope.row.status == 3">运输中</p>
                                <p v-if="scope.row.status == 4">已完成</p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="haven" label="港口" width="150" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="voyage" label="船名/航次" width="220" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.shipName }}/{{ scope.row.voyage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cutOffTimeBeg" label="截单时间" width="200" show-overflow-tooltip>
                    </el-table-column>
                    <!-- <el-table-column prop="boxMaking" label="做箱日期" width="150" show-overflow-tooltip>
                    </el-table-column> -->
                    <!-- <el-table-column prop="caseQuantity" label="箱量" width="150" show-overflow-tooltip>
                    </el-table-column> -->
                    <el-table-column prop="createTime" label="下单时间" width="200" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="storeAddress" label="门店地址" width="200" show-overflow-tooltip>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination background @current-change="handleSizeChange" layout="prev, pager, next"
                    :total="paging.total" :page-size="paging.size">
                </el-pagination>
            </div>
        </div>
        <See_Component v-if="seeFlag" :changeSee="changeSee" :orderNo="orderNo"></See_Component>
        <BoxMakingTime_Component v-if="boxMakingFlag" :changeboxMaking='changeboxMaking' :orderNo="orderNo">
        </BoxMakingTime_Component>
        <ContainerInformation_Com v-if="containerFlag" :changecontainer="changecontainer" :orderNo="orderNo"
            :mainBillNo="mainBillNo">
        </ContainerInformation_Com>
        <transitPort_Component v-if="transitPortFlag" :changetransitPort="changetransitPort" :mainBillNo="mainBillNo">
        </transitPort_Component>
    </div>
</template>

<script>
import See_Component from '../TrailerOrder/See_Component.vue';
import BoxMakingTime_Component from '../TrailerOrder/BoxMakingTime_Component.vue';
import ContainerInformation_Com from '../TrailerOrder/ContainerInformation.vue';
import transitPort_Component from '../TrailerOrder/transitPort_Component.vue';
import axios from 'axios';
import { endSeaList } from '../../tools/ajax';
import { baseUrl } from '../../tools/baseUrl';
export default {
    name: "TrailerOrder_Component",
    components: { See_Component, BoxMakingTime_Component, ContainerInformation_Com, transitPort_Component },
    data() {
        return {
            seeFlag: false, //查看
            boxMakingFlag: false, //做箱时间
            containerFlag: false,//货箱信息
            transitPortFlag: false,//中转港
            blNoValue: "", //提单号
            orderStatusValue: "", //订单状态
            orderStatusList: [{
                value: '-1',
                label: '已取消'
            }, {
                value: '1',
                label: '已下单'
            }, {
                value: '2',
                label: '已接单'
            }, {
                value: '3',
                label: '运输中'
            }, {
                value: '4',
                label: '已完成'
            },],
            portValue: "", //港口
            portList: [],
            orderRangeValue: "", //下单范围日期
            addressValue: "", //门点地址
            caseNoValue: "",//箱号
            businessNoValue: "",//业务编号

            TrailerOrderList: [],//列表
            tableData: [{
                orderStatus: '已接单',
                port: '宁波港',
                shipName: 'MAERSK HERRERA/247W',
                openingTime: '11-28 10:00',
                boxMakingDate: '2022-11-29',
                caseQuantity: 1,
                orderTime: "2022-11-28 10:34",
                storeAddress: "浙江省宁波市象山县西周镇崟港路103号",
            }, {
                orderStatus: '已接单',
                port: '宁波港',
                shipName: 'MAERSK HERRERA/247W',
                openingTime: '11-28 10:00',
                boxMakingDate: '2022-11-29',
                caseQuantity: 1,
                orderTime: "2022-11-28 10:34",
                storeAddress: "浙江省宁波市象山县西周镇崟港路103号",
            }],
            paging: {
                total: 0,
                size: 5,
            },

            formData: { //港口
                pageNo: 1, //当前页

            },
            orderNo: "",//订单号
            mainBillNo: "",//主提单号
        }
    },
    created() {
        endSeaList().then((res) => {
            this.portList = res.result.records;
        });
        this.getTrailerOrderList();
    },
    methods: {
        getTrailerOrderList() {
            let token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/trailer/trailerList`, {
                headers: { 'authorization': token }
            })
                .then(res => {
                    console.log(res.data.result.records);
                    this.TrailerOrderList = res.data.result.records;
                    this.paging.total = res.data.result.total;
                })
                .catch(err => {
                    console.error(err);
                })
        },
        handleSearch() { //搜索
            let token = sessionStorage.getItem("token");
            console.log(this.orderStatusValue);
            if (this.orderRangeValue) {
                var orderRangeValue1 = this.orderRangeValue[0];
                var orderRangeValue2 = this.orderRangeValue[1];
            }
            axios.get(`${baseUrl}/trailer/trailerList`, {
                params: {
                    bill_no: this.blNoValue,//提单号
                    order_no: this.businessNoValue, //业务编号
                    haven: this.portValue,//港区
                    packing_no: this.caseNoValue,//装箱号
                    store_address: this.addressValue,//门点地址
                    begTime: orderRangeValue1,//开始时间
                    endTime: orderRangeValue2,//结束时间
                    status: this.orderStatusValue,//状态
                },
                headers: { 'authorization': token }
            })
                .then(res => {
                    console.log(res.data.result);
                    this.TrailerOrderList = res.data.result.records;
                    this.paging.total = res.data.result.total;
                })
                .catch(err => {
                    console.error(err);
                })
        },
        handerReset() { //重置
            this.blNoValue = this.orderStatusValue = this.portValue = this.orderRangeValue = this.addressValue = this.caseNoValue = this.businessNoValue = ''

        },

        handleAnotherOrder() { //再来一单

        },
        handleSee(row) { //查看
            console.log(row);
            this.orderNo = row.orderNo;
            this.seeFlag = true
        },
        changeSee() { //取消/确定
            this.seeFlag = false
        },
        handleBoxMakingTime(row) { //做箱时间
            this.orderNo = row.orderNo;
            this.boxMakingFlag = true;
        },
        changeboxMaking() {
            this.boxMakingFlag = false
        },
        handleContainer(row) { //货箱信息
            this.orderNo = row.id;
            this.mainBillNo = row
            console.log("row", row);
            this.containerFlag = true
        },
        changecontainer() {
            this.containerFlag = false
        },
        handletransitPort(row) {
            this.mainBillNo = row;
            console.log(row);
            this.transitPortFlag = true
        },
        changetransitPort() {
            this.transitPortFlag = false
        },
        handleBlNo() { //提单号

        },

        handleSizeChange(val) { //分页
            var token = sessionStorage.getItem("token");
            if (this.orderRangeValue) {
                var orderRangeValue1 = this.orderRangeValue[0];
                var orderRangeValue2 = this.orderRangeValue[1];
            }
            axios.get(`${baseUrl}/trailer/trailerList`, {
                params: {
                    pageNo: val,
                    bill_no: this.blNoValue,//提单号
                    order_no: this.businessNoValue, //业务编号
                    haven: this.portValue,//港区
                    packing_no: this.caseNoValue,//装箱号
                    store_address: this.addressValue,//门点地址
                    begTime: orderRangeValue1,//开始时间
                    endTime: orderRangeValue2,//结束时间
                    status: "",//状态
                },
                headers: { 'authorization': token }
            })
                .then(res => {
                    console.log(res.data.result);
                    this.TrailerOrderList = res.data.result.records
                })
                .catch(err => {
                    console.error(err);
                })
        },

        // 滑动加载
        loadmore() {
            this.formData.pageNo++;
            this.getList(this.formData);
        },
        getList(formData) {
            endSeaList({ pageNo: formData.pageNo }).then((res) => {
                const _res = res.result.records;
                this.portList = [...this.portList, ..._res];

            })
        },
        changeFous() {
            this.formData.pageNo = 1;
            endSeaList().then(res => {
                // console.log(res.result.records);
                this.portList = res.result.records;
                // this.destinationListList = res.result.records;
            })
        },
        remoteMethod(value) { //
            // console.log("港口", value);
            if (value) {
                endSeaList({ name: value }).then((res) => {
                    console.log(res.result);
                    this.portList = res.result.records
                })
            } else {
                endSeaList().then(res => {
                    // console.log(res.result);
                    this.portList = res.result.records;
                    // this.destinationListList = res.result.records;
                });
            }

        },
    },
}
</script>

<style lang="scss" scoped>
.flexibleLayout {
    display: flex;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 649px;
    //     /*滚动条宽度*/
    height: 8px;
    //     /*滚动条高度*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    border-radius: 0px;
    /*滚动条的背景区域的圆角*/

    background: rgba(153, 153, 153, 0.2);
    /*滚动条的背景颜色*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    /*滚动条的圆角*/

    background: #999999;
    /*滚动条的背景颜色*/
}

::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    // background: #f4f4f4;
    background: transparent;
}

::v-deep .el-table .el-table__cell {
    padding: 0;
}

::v-deep .el-date-editor {
    width: 100%;
    height: 100%;
}

::v-deep .nanas {
    text-align: center;

    ::v-deep .cell {
        text-align: center;

    }
}

.trailerBox {
    margin: 0 auto;
    // max-width: 1526px;
    // min-height: 666px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    padding-top: 24px;
    padding-left: 32px;
    // padding-bottom: 23px;

    .trailerServer {
        padding-right: 32px;

        .blNo {
            width: 180px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
        }

        .selectItem {
            width: 180px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            margin-left: 32px;
        }

        .orderRange {
            width: 240px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            margin-left: 32px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
        }

        .gatePointAddress {
            width: 260px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            margin-left: 32px;
        }

        .selectItem2 {
            width: 260px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
        }

        .searchButton {
            width: 120px;
            height: 40px;
            background: #FFC13B;
            box-shadow: 0px 3px 6px 1px rgba(255, 193, 59, 0.3);
            border-radius: 4px 4px 4px 4px;
            line-height: 40px;
            text-align: center;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #2B2B2B;
            margin-left: 32px;
            cursor: pointer;
        }

        .resetButton {
            width: 80px;
            height: 40px;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid rgba(43, 43, 43, .3);
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #2B2B2B;
            margin-left: 32px;
            cursor: pointer;
        }
    }

    .tabs {
        margin-top: 60px;
        padding-bottom: 32px;
        padding-right: 32px;

        ::v-deep .el-table__row {
            height: 84px;
        }
        ::v-deep .el-button--text {
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #007AFF;
        }
        .el-table {
            font-size: 14px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            color: #333333;
        }
        .paging {
            display: flex;
            justify-content: flex-end;
            position: relative;
            margin-top: 32px;
        }
    }
}
</style>