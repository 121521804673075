<template>
    <div class="seeMask">
        <div class="seeBox">
            <div class="titles">
                <span>订单详情</span>
            </div>
            <div class="seeContent">
                <div class="firstLine">
                    <ul class="ul1">
                        <li>
                            <span>港口：{{ detailList.haven }}</span>
                        </li>
                        <li>
                            <span>提单号(用于放箱)：{{ detailList.billNo }}</span>
                        </li>
                        <li>
                            <span>船名：{{ detailList.shipName }}</span>
                        </li>
                        <li>
                            <span>航次：{{ detailList.voyage }}</span>
                        </li>
                        <li>
                            <span>港区：{{ detailList.dock }}</span>
                        </li>
                        <li>
                            <span>中转港：{{ detailList.entreport }}</span>
                        </li>
                        <li>
                            <span>目的港：{{ detailList.destination }}</span>
                        </li>

                    </ul>
                </div>
                <div class="secondLine">
                    <ul class="ul2">
                        <li>工厂地址：{{ detailList.factoryExclusion }}</li>
                        <li>工厂联系人：{{ detailList.factoryContact }}</li>
                        <li>联系人电话：{{ detailList.factoryMobile }}</li>
                        <li>门点箱货信息来源：不固定，客户或者门点提供</li>
                    </ul>
                </div>
                <!-- 表格 -->
                <div class="formTable">

                    <el-table :data="tableList" border style="width: 100%"
                        :header-cell-style="{ 'background': 'rgba(112, 112, 112, .3)' }"
                        :header-row-style="{ height: '50px' }" :row-style="{ height: '56px' }">
                        <el-table-column type="index" label="序号" class-name="tableHeader" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="boxMakingTime" label="做箱时间" class-name="tableHeader"
                            show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="billNo" label="提单号" class-name="tableHeader" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="boxType" label="箱型" class-name="tableHeader" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="pkg" label="件数" class-name="tableHeader" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="kgs" label="毛重(kg)" class-name="tableHeader" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="dayton" label="预计货重(吨)" class-name="tableHeader" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="cbm" label="体积(m³)" class-name="tableHeader" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="productName" label="品名" class-name="tableHeader" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="withholding" label="预提" class-name="tableHeader" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="houseAwb" label="副提单" class-name="tableHeader" show-overflow-tooltip>
                        </el-table-column>
                    </el-table>
                </div>
                <!-- 要求 -->
                <div class="requirement">
                    <ul>
                        <li v-if="detailList.note">做箱要求： {{ detailList.note }}</li>
                        <li>是否已放设备单？{{ detailList.etcElr == '0' ? '否' : "是" }}</li>
                        <li>截单时间：{{ detailList.cutOffTimeBeg }}</li>
                        <li>是否出数据截单？ {{ detailList.isCutOff == '0' ? '否' : "是" }}</li>
                        <li>是否为SOC箱？ {{ detailList.shippingSpace == '0' ? '否' : "是" }}</li>
                        <li>是否需要过磅？ {{ detailList.isNeedWeigh == '0' ? '否' : "是" }}</li>
                        <li>检验监装: {{ detailList.needExamine == '0' ? '否' : detailList.needExamine == '1' ? '专业司机检验员' :
                                '委派检验员现场检验'
                        }}
                        </li>
                    </ul>
                </div>
                <!-- 按钮 -->
                <div class="bottomBtn">
                    <div class="cancelButton" @click="handleCancel"><span>取消</span></div>
                    <div class="okButton" @click="handleOk"><span>确定</span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { baseUrl } from '../../tools/baseUrl';
export default {
    name: "See_Component",
    props: ['changeSee', 'orderNo'],
    data() {
        return {
            tableData: [{
                date: '2016-05-03',
                name: '王小虎',
                address: '委托预提(2022-11-25 00:00:00)'
            }],
            detailList: [],
            tableList: [],
        }
    },
    created() {
        this.getTrailerDetail()
    },
    methods: {
        getTrailerDetail() {
            let orderNo = this.orderNo;
            let token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/trailer/detail`, {
                //参数列表
                params: { orderNo },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    console.log(res.data.result);
                    this.tableList = res.data.result.pullcartExtList
                    this.detailList = res.data.result;
                    console.log(this.detailList);
                })
                .catch(err => {
                    console.error(err);
                })
        },
        handleCancel() { //取消
            this.changeSee()
        },
        handleOk() { //确认
            this.changeSee()
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__row {
    // height: 40px !important;
}

.seeMask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.3);
    z-index: 100;

    .seeBox {
        width: 1100px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
        border-radius: 8px 8px 8px 8px;
        margin: 100px auto 0;
        padding-bottom: 24px;

        .titles {
            height: 56px;
            line-height: 56px;
            border-bottom: 1px solid rgba(153, 153, 153, 1);
            font-size: 24px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            padding-left: 25px;
        }

        .seeContent {
            margin-top: 25px;
            padding: 0 24px;

            .firstLine {
                .ul1 {
                    display: flex;
                    justify-content: space-between;

                    li {
                        font-size: 12px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }

            .secondLine {
                margin-top: 25px;

                .ul2 {
                    display: flex;
                    justify-content: space-between;

                    li {
                        font-size: 12px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }

            .formTable {
                margin-top: 30px;



                ::v-deep .tableHeader {
                    font-size: 12px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                }

                .ul1 {
                    display: flex;
                    justify-content: space-between;

                    li {
                        font-size: 12px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;
                        // padding: 0px 16px;
                        border-right: 1px solid rgba(112, 112, 112, .5);
                        border-top: 1px solid rgba(112, 112, 112, .5);
                        flex: 1;
                        text-align: center;
                        line-height: 40px;
                    }

                    li:nth-child(1) {
                        border-left: 1px solid rgba(112, 112, 112, .5);
                    }
                }

                .tables {
                    width: 100%;
                    font-size: 12px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                }
            }

            .requirement {
                margin-top: 24px;
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;

                ul {
                    li {
                        margin-top: 15px;
                    }
                }
            }

            .bottomBtn {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 66px;
                border-top: 1px solid rgba(153, 153, 153, 1);
                margin-top: 24px;

                div {
                    width: 60px;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                    border-radius: 4px 4px 4px 4px;
                    cursor: pointer;
                }

                .cancelButton {
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                    margin-right: 24px;
                }

                .okButton {
                    background: #1890FF;
                    font-size: 16px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
    }
}
</style>